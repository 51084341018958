import {
  SignInButton,
  SignUpButton,
  SignedOut,
  useUser,
} from "@clerk/clerk-react";
import Container from "react-bootstrap/Container";
import Superhero from "../../images/girlpizzaeatinganime.svg";
// import msStartup from "../../images/logo-founders-hub.svg";
import logoFmp from "../../images/logo-fmp.svg";
import Logo from "../../images/logo-transparent.svg";
import ArrowRight from "../../images/icon-arrow-right.svg";
import ArrowRightBlue from "../../images/arrow-right-blue.svg";
import LogoFooter from "../../images/logo-variant-1.svg";
import MediaIcon1 from "../../images/media-icon-1.png";
import MediaIcon2 from "../../images/media-icon-2.png";
import MediaIcon3 from "../../images/media-icon-3.png";
import MediaIcon4 from "../../images/media-icon-4.png";
import MediaIcon5 from "../../images/media-icon-5.png";
import investmentCardLeft from "../../images/investment-card-left.png";
import investmentCardRight from "../../images/investment-card-right.png";
import iconMetricsSun from "../../images/icon-metrics-sun.svg";
// import iconMetricsCloud from "../../images/icon-metrics-cloud.svg";
// import iconMetricsThunder from "../../images/icon-metrics-thunder.svg";
import iconRocket from "../../images/icon-rocket.svg";
import iconBulb from "../../images/icon-bulb.svg";
import iconHomeHeart from "../../images/icon-home-heart.svg";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Stack,
} from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { ApplicationRoutes } from "../../constants/application-routes";
import uiScrollHelper from "../../components/utils/ui-scroll-helper";
import RemoveSharpIcon from "@mui/icons-material/RemoveSharp";
// import AddSharpIcon from '@mui/icons-material/AddSharp';
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import Typography from "@mui/material/Typography";
import React from "react";

function Landing() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState(
    ApplicationRoutes.Home.toString()
  );
  const { isSignedIn, user } = useUser();
  const { clearSessionStorageKeys } = uiScrollHelper();
  const testimonal = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    centerPadding: "10px",
    arrows: false,
    slidesToShow: 4,
    speed: 500,
    dots: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  useEffect(() => {
    clearSessionStorageKeys();
    const redirectUrAfterLogin = location?.state
      ? location?.state
      : ApplicationRoutes.Home.toString();
    setRedirectUrl(redirectUrAfterLogin);
    if (isSignedIn && user !== null) {
      navigate(redirectUrl);
    } // eslint-disable-next-line
  }, [isSignedIn, user, navigate]);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      <div className="sb-landing-wrap">
        <Grid container>
          <Grid item md={12} sm={12}>
            <Container>
              <Grid item md={12} sm={12}>
                <div className="landing-logo">
                  <img
                    src={Logo}
                    alt="logo"
                    width={200}
                    height={40}
                  />
                </div>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="sb-main-content"
              >
                <Grid item md={7} sm={12}>
                  <div className="mb-4">
                    <TypeAnimation
                      preRenderFirstString={true}
                      sequence={[
                        // Same substring at the start will only be typed once, initially
                        "Discover Stocks While You Enjoy Your Pizza!",
                        1000,
                        "Discover Stocks While You Brew Your Coffee!",
                        1000,
                        "Discover Stocks While You Wait for Uber!",
                        1000,
                        // "Discover Stocks While Your Netflix Show Loads!",
                        // 1000,
                        // "Discover Stocks While You Microwave Popcorn!",
                        // 1000,
                        "Discover Stocks While You Walk Your Dog!",
                        1000,
                      ]}
                      speed={50}
                      className="text-render"
                      repeat={Infinity}
                    />
                  </div>
                  <Grid>
                    <Grid item md={12} sm={12}>
                      <div className="mb-4">
                        <p className="landing-tagline-secondary mb-1">
                          Quick, Easy, and Powerful stock analysis for
                          Beginners.
                        </p>
                        <p className="landing-tagline-secondary mb-1">
                          Try us today. It’s Free. And No Credit Card Required.
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <div>
                    <div className="mb-4">
                      <SignedOut>
                        <SignUpButton
                          redirectUrl={redirectUrl}
                          children={
                            <div className="">
                              {/* <button className="signin-btn">Join for free</button> */}
                              <button className="primary-variant">
                                Get Started Now{" "}
                                <img src={ArrowRight} alt="arrow right" />
                              </button>
                            </div>
                          }
                        />
                      </SignedOut>
                    </div>
                    {/* <SignedIn>
                        <button className="signin-btn">Go to Dashboard</button>
                      </SignedIn> */}
                    {!user && (
                      <div className="primary-txt-variant">
                        Already a ‘bruh’?
                        <SignInButton
                          redirectUrl={redirectUrl}
                          children={
                            <span className="link-txt">Sign In Here</span>
                          }
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item md={5} sm={12}>
                  <img src={Superhero} alt="superhero" className="img-fluid" />
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </div>
      <Box component="section" className="">
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid item md={12} sm={12} xs={12}>
            <Container>
              <div className="user-section">
                <div className="d-flex justify-content-center align-items-center flex-column gap-1">
                  <h2 className="sb-section-header mb-1">
                    What our users say?
                  </h2>
                  <div className="sb-section-sub-header mb-4">
                    Trusted by over 31,000 users
                  </div>
                </div>
                <div className="slider-container variant-3">
                  <Slider {...testimonal}>
                    <div className="h-100">
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <img src={MediaIcon1} alt="media icon" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="media-header-txt-20 mb-1">
                                @Stockbruh_User
                              </div>
                              {/* <div className="media-header-txt-16">122k Followers</div> */}
                            </div>
                          </div>
                          <div className="media-header-txt-16">
                            “I thought this was a joke at first glance (just
                            reading url lol) but after checking it out is pretty
                            neat what you did there. This is way better beginner
                            level information than most places beginner
                            investors look. If you did indeed build this...great
                            job!.”
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    <div className="h-100">
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <img src={MediaIcon2} alt="media icon" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="media-header-txt-20 mb-1">
                                @Stockbruh_User
                              </div>
                              {/* <div className="media-header-txt-16">122k Followers</div> */}
                            </div>
                          </div>
                          <div className="media-header-txt-16">
                            “Thank you it was very useful. it will help me
                            figure our which companies are actually worth
                            investing in.”
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    <div className="h-100">
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <img src={MediaIcon3} alt="media icon" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="media-header-txt-20 mb-1">
                                @Stockbruh_User
                              </div>
                              {/* <div className="media-header-txt-16">324k Followers</div> */}
                            </div>
                          </div>
                          <div className="media-header-txt-16">
                            “You have created a great website even my father, an
                            old school investor to the core likes it.”
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    <div className="h-100">
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <img src={MediaIcon4} alt="media icon" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="media-header-txt-20 mb-1">
                                @Stockbruh_User
                              </div>
                              {/* <div className="media-header-txt-16">223k Followers</div> */}
                            </div>
                          </div>
                          <div className="media-header-txt-16">
                            “I like how it simplifies everything. Investing is
                            filled with vocabulary that makes people think they
                            need professional help. This makes it easy to
                            understand”
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                    <div className="h-100">
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <div className="d-flex align-items-center mb-3">
                            <div className="flex-shrink-0">
                              <img src={MediaIcon5} alt="media icon" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="media-header-txt-20 mb-1">
                                @Stockbruh_User
                              </div>
                              {/* <div className="media-header-txt-16">122k Followers</div> */}
                            </div>
                          </div>
                          <div className="media-header-txt-16">
                            “Thank you it was very useful. it will help me
                            figure our which companies are actually worth
                            investing in.”
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </Slider>
                </div>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <Box component="section" className="works-flow-section">
        <div className="corner-bg">
          <Grid container>
            <Grid item md={12} sm={12}>
              <Container>
                <Grid container justifyContent={"center"} alignItems={"center"}>
                  <Grid item lg={9} md={12} sm={12}>
                    <div className="work-flow-content">
                      <h3 className="sb-section-header text-white mb-4">
                        How it works
                      </h3>
                      <div className="work-flow-arrow">
                        <Grid container spacing={3}>
                          <Grid item lg={4} md={4} sm={12}>
                            <div className="card-gradient-variant">
                              <img
                                src={iconRocket}
                                alt="icon"
                                width={100}
                                height={100}
                              />
                              <div className="cg-header">Discover</div>
                              <p className="cg-content mb-0">
                                Discover stocks and ETFs like you would discover
                                pictures in Instagram
                              </p>
                            </div>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12}>
                            <div className="card-gradient-variant top-middle">
                              <img
                                src={iconBulb}
                                alt="icon"
                                width={100}
                                height={100}
                              />
                              <div className="cg-header">Understand</div>
                              <p className="cg-content mb-0">
                                Simple plain English analysis for easy
                                understanding of financial fundamentals
                              </p>
                            </div>
                          </Grid>
                          <Grid item lg={4} md={4} sm={12}>
                            <div className="card-gradient-variant">
                              <img
                                src={iconHomeHeart}
                                alt="icon"
                                width={100}
                                height={100}
                              />
                              <div className="cg-header">Save</div>
                              <p className="cg-content mb-0">
                                ‘Like’ and save stocks and ETFs to track them
                                for future
                              </p>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </div>
      </Box>
      <Box component="section" className="stuff-section-wrap">
        <Grid container>
          <Grid item md={12} sm={12}>
            <Container>
              <Grid
                container
                spacing={3}
                alignItems={"center"}
                className="mb-4"
              >
                <Grid item md={6} sm={12}>
                  <p className="sb-section-sub-header dark-txt">
                    Begin your investment journey by discovering stocks and ETFs
                    like you discover pictures in Instagram
                  </p>
                </Grid>
                <Grid item md={6} sm={12}>
                  <img
                    src={investmentCardRight}
                    alt="card-right"
                    className="img-fluid"
                  />
                </Grid>
              </Grid>
              <Grid container alignItems={"center"} className="mb-4">
                <Grid item md={6} sm={12}>
                  <img
                    src={investmentCardLeft}
                    alt="card-left"
                    className="img-fluid"
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <p className="sb-section-sub-header text-center dark-txt">
                    Like something? Save it to your favorites
                  </p>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <div>
                  <Grid container alignItems={"center"} className="mb-3">
                    <Grid item md={6} xs={12}>
                      <h3 className="sb-section-header">
                        Stuff you absolutely must know about them
                      </h3>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <h4 className="sb-section-sub-header">
                        Easy to understand analysis tailored for beginners
                      </h4>
                    </Grid>
                  </Grid>

                  <Accordion
                    disabled
                    className="sb-accordion border-variant-1 mb-2"
                    expanded={expanded === `panel${1}`}
                    onChange={handleChange(`panel${1}`)}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${1}` ? (
                          <RemoveSharpIcon />
                        ) : (
                          <ArrowForwardOutlinedIcon />
                        )
                      }
                      aria-controls={`panel${1}-content`}
                      id={`panel${1}-header`}
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          // order: -1,
                          marginRight: "0.5rem",
                          color: "#6F31EF",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        className="w-100"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <Typography>
                            Is the stock giving long term returns?
                          </Typography>
                        </div>
                        <div>
                          <img src={iconMetricsSun} alt="metric-sun" />
                        </div>
                      </Stack>
                      {/* <Typography>Collapsible Group Item #1</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Stock Price Recruit Holdings Co., Ltd.'s stock
                        price grew on an average of 15.14 % year on year for the
                        past 5 years which means a USD 1000 invested in Recruit
                        Holdings Co., Ltd. in 2019 would
                        have grown to USD 2023.55 today
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled
                    className="sb-accordion border-variant-1 mb-2"
                    expanded={expanded === `panel${2}`}
                    onChange={handleChange(`panel${2}`)}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${2}` ? (
                          <RemoveSharpIcon />
                        ) : (
                          <ArrowForwardOutlinedIcon />
                        )
                      }
                      aria-controls={`panel${2}-content`}
                      id={`panel${2}-header`}
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          // order: -1,
                          marginRight: "0.5rem",
                          color: "#6F31EF",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        className="w-100"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <Typography>Are they profitable?</Typography>
                        </div>
                        <div>
                          <img src={iconMetricsSun} alt="metric-sun" />
                        </div>
                      </Stack>
                      {/* <Typography>Collapsible Group Item #1</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Stock Price Recruit Holdings Co., Ltd.'s stock
                        price grew on an average of 15.14 % year on year for the
                        past 5 years which means a USD 1000 invested in Recruit
                        Holdings Co., Ltd. in 2019 would
                        have grown to USD 2023.55 today
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled
                    className="sb-accordion border-variant-1 mb-2"
                    expanded={expanded === `panel${3}`}
                    onChange={handleChange(`panel${3}`)}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${3}` ? (
                          <RemoveSharpIcon />
                        ) : (
                          <ArrowForwardOutlinedIcon />
                        )
                      }
                      aria-controls={`panel${3}-content`}
                      id={`panel${3}-header`}
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          // order: -1,
                          marginRight: "0.5rem",
                          color: "#6F31EF",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        className="w-100"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <Typography>Are they growing?</Typography>
                        </div>
                        <div>
                          <img src={iconMetricsSun} alt="metric-sun" />
                        </div>
                      </Stack>
                      {/* <Typography>Collapsible Group Item #1</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Stock Price Recruit Holdings Co., Ltd.'s stock
                        price grew on an average of 15.14 % year on year for the
                        past 5 years which means a USD 1000 invested in Recruit
                        Holdings Co., Ltd. in 2019 would
                        have grown to USD 2023.55 today
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled
                    className="sb-accordion border-variant-1 mb-2"
                    expanded={expanded === `panel${4}`}
                    onChange={handleChange(`panel${4}`)}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${4}` ? (
                          <RemoveSharpIcon />
                        ) : (
                          <ArrowForwardOutlinedIcon />
                        )
                      }
                      aria-controls={`panel${4}-content`}
                      id={`panel${4}-header`}
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          // order: -1,
                          marginRight: "0.5rem",
                          color: "#6F31EF",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        className="w-100"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <Typography>Earnings Hit or Miss</Typography>
                        </div>
                        <div>
                          <img src={iconMetricsSun} alt="metric-sun" />
                        </div>
                      </Stack>
                      {/* <Typography>Collapsible Group Item #1</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Stock Price Recruit Holdings Co., Ltd.'s stock
                        price grew on an average of 15.14 % year on year for the
                        past 5 years which means a USD 1000 invested in Recruit
                        Holdings Co., Ltd. in 2019 would
                        have grown to USD 2023.55 today
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disabled
                    className="sb-accordion border-variant-1 mb-2"
                    expanded={expanded === `panel${5}`}
                    onChange={handleChange(`panel${5}`)}
                    disableGutters={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${5}` ? (
                          <RemoveSharpIcon />
                        ) : (
                          <ArrowForwardOutlinedIcon />
                        )
                      }
                      aria-controls={`panel${5}-content`}
                      id={`panel${5}-header`}
                      sx={{
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-expandIconWrapper": {
                          // order: -1,
                          marginRight: "0.5rem",
                          color: "#6F31EF",
                        },
                      }}
                    >
                      <Stack
                        direction="row"
                        className="w-100"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <div>
                          <Typography>Do they have cash?</Typography>
                        </div>
                        <div>
                          <img src={iconMetricsSun} alt="metric-sun" />
                        </div>
                      </Stack>
                      {/* <Typography>Collapsible Group Item #1</Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Stock Price Recruit Holdings Co., Ltd.'s stock
                        price grew on an average of 15.14 % year on year for the
                        past 5 years which means a USD 1000 invested in Recruit
                        Holdings Co., Ltd. in 2019 would
                        have grown to USD 2023.55 today
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>

      <Box component="section" className="get-started-section">
        <Grid container alignItems={"center"}>
          <Grid item lg={12} md={12} sm={12}>
            <Container>
              <div className="card-linear-variant-1">
                <h3 className="media-header-txt-32 mb-2">Get Started Today</h3>
                <Grid item md={4} sm={12}>
                  <p className="media-header-txt-20">
                    Become an informed investor with Stockbruh's easy to
                    understand analysis.
                  </p>
                </Grid>
                <SignedOut>
                  <SignUpButton
                    redirectUrl={redirectUrl}
                    children={
                      <div className="">
                        {/* <button className="signin-btn">Join for free</button> */}
                        <button className="secondary-variant">
                          Sign Up <img src={ArrowRightBlue} alt="arrow right" />
                        </button>
                      </div>
                    }
                  />
                </SignedOut>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Box>

      <Box component="footer" className="sb-footer-wrap">
        <Grid container>
          <Grid item md={12} sm={12}>
            <Container>
              <Grid container spacing={3} alignItems={"center"}>
                <Grid item lg={4} md={12} sm={12}>
                  <div className="footer-logo">
                    <img src={LogoFooter} alt="Logo" width={124} height={24} />
                    <p className="footer-txt mb-0">
                      Stockbruh is an innovative platform designed to simplify
                      stock market analysis for beginner investors. By
                      eliminating complex technical jargon, Stockbruh makes it
                      easier to understand the fundamental aspects of stocks and
                      ETFs.
                    </p>
                  </div>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <div className="d-flex align-items-center flex-md-row flex-column justify-content-center gap-3">
                    {/* <Link
                      href="https://foundershub.startups.microsoft.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={msStartup} alt="Microsoft Startup founder hub" width={150} />
                    </Link> */}
                    <Link
                      href="https://site.financialmodelingprep.com/developer/docs"
                      target="_blank"
                      rel="noreferrer"
                      className="text-decoration-none"
                    >
                      <div className="media-header-txt-16">
                        Data provided by
                      </div>
                      <img src={logoFmp} alt="fmp logo" width={200} />
                    </Link>
                  </div>
                </Grid>
                <Grid item lg={4} md={6} sm={6} xs={12}>
                  <div className="text-center">
                    <SignedOut>
                      <SignUpButton
                        redirectUrl={redirectUrl}
                        children={
                          <button className="primary-variant size-md">
                            Get Started Now{" "}
                            <img src={ArrowRight} alt="arrow right" />
                          </button>
                        }
                      />
                    </SignedOut>
                  </div>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                      <div className="footer-txt">
                        © <strong>Stockbruh</strong>, Inc. All Rights Reserved.
                      </div>
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <div className="footer-links">
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <a href={ApplicationRoutes.Privacy}>
                              Privacy Policy
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={ApplicationRoutes.Terms}>Terms of Use</a>
                          </li>
                          <li className="list-inline-item">
                            <a href={ApplicationRoutes.Disclaimer}>
                              Disclaimer
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a href={ApplicationRoutes.Faq}>FAQ</a>
                          </li>
                          <li className="list-inline-item">
                            <span
                              className="cursor-pointer link-txt"
                              onClick={handleClickOpen}
                            >
                              Contact Us
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Box>

      <>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          maxWidth={"xs"}
          open={open}
        >
          <DialogContent dividers>
            <div className="d-flex mb-3 justify-content-between align-items-center">
              <div className="primary-header-h4">Contact Us</div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div>
              <h5 className="primary-txt-16 mb-3">
                Reach us for questions, suggestions or just to say Hi!:
              </h5>
              <ul>
                <li>
                  Our email:{" "}
                  <a href="mailto:support@stockbruh.com">
                    support@stockbruh.com
                  </a>
                </li>
              </ul>
            </div>
          </DialogContent>
        </Dialog>
      </>
    </div>
  );
}
export default Landing;
