import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ClerkProvider } from "@clerk/clerk-react";
import "./core-styling/sb-theme.scss";
import { PostHogProvider, usePostHog} from 'posthog-js/react';
import { useUser } from '@clerk/clerk-react';

import reportWebVitals from './reportWebVitals';
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const options = {
  api_host: 'https://us.i.posthog.com'
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const TrackUser = () => {
  const { user } = useUser();
  const posthog = usePostHog();

  useEffect(() => {
    if (user && posthog) {
      posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress || 'No email',
        name: user.fullName || 'Anonymous',
      });
    }
  }, [user, posthog]);

  return null;
};

root.render(
  <React.StrictMode>  
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <PostHogProvider 
      apiKey={'phc_Agirwiv5QBSUHTxAr9V2EZHjDlgj8M7iuLc1E2c9dBT'}
      options={options}
      >
        <App />
        <TrackUser />
      </PostHogProvider>
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
