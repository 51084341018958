import { Grid, TextField } from "@mui/material";
import React from "react";
import arrowMaps from "../../images/arrow-maps.svg";

const AiHubWidget = () => {
    return(
        <Grid
        container
        direction="row"
        sx={{
        justifyContent: "center",
        alignItems: "center",
        }}
        >
        <Grid item md={8}>
        <div className="card-variant-purple mb-3">
        <h2 className="primary-card-header">Validate your strategy with AI</h2>
        <h6 className="primary-sub-header mb-3">e.g. Apple 10%, Microsoft 20%, VOO - 30%, NVDA - 10%, Walmart - 20%, Party City 10%</h6>
        <div className="d-flex align-items-center">
            <div className="flex-grow-1">
            <TextField
            variant="outlined"
            fullWidth 
            placeholder={"Submit your portfolio for review. E.g Apple 10%, Microsoft 20%, VOO - 30%, NVDA - 10%, Walmart - 20%, Party City 10%"}
            className="form-input variant-1"
        />
            </div>
            <div className="flex-shrink-0 ms-2">
            <button className="icon-btn-variant">
                <img src={arrowMaps} alt="arrow-maps" />
            </button>
            </div>
        </div>
        </div>
        <div className="card-primary">
        <div className="d-flex justify-content-end gap-2 align-items-center">
            <button className="secondary-btn">Cancel</button>
            <button className="primary-btn">Save</button>
        </div>
        </div>
        </Grid>
        </Grid>
    );
};
export default AiHubWidget;