import { SessionStorageKeys } from "../../constants/storage-constants";

const uiScrollHelper = () => {
  const defaultRecordsPerPage = 20;
  const setHomePageScrollPosition = (scrollHeight: number) => {
    sessionStorage.setItem(
      SessionStorageKeys.HomeScrollDivHeight,
      scrollHeight?.toString()
    );
  };
  const getHomePageScrollPosition = () => {
    const scrollHeight = sessionStorage.getItem(
      SessionStorageKeys.HomeScrollDivHeight
    );
    return parseInt(scrollHeight || "0");
  };
  const setHomeWindowScrollPosition = (scrollHeight: number) => {
    sessionStorage.setItem(
      SessionStorageKeys.HomeWindowScrollHeight,
      scrollHeight?.toString()
    );
  };
  const getHomeWindowScrollPosition = () => {
    const scrollHeight = sessionStorage.getItem(
      SessionStorageKeys.HomeWindowScrollHeight
    );
    return parseInt(scrollHeight || "0");
  };
  const setCurrentPage = (pageNo: number) => {
    sessionStorage.setItem(
      SessionStorageKeys.HomeCurrentPage,
      pageNo?.toString()
    );
  };

  const getCurrentPageFromSession = () => {
    const currentPage = sessionStorage.getItem(
      SessionStorageKeys.HomeCurrentPage
    );
    return currentPage ? parseInt(currentPage) : 1;
  };
  const getPreviousPageOnInit = () => {
    const currentPage = getCurrentPageFromSession();
    const previousPage = currentPage > 1 ? currentPage - 1 : 0;
    return previousPage;
  };
  const getRecordsPerPage = () => {
    const currentPage = getCurrentPageFromSession() * defaultRecordsPerPage;
    return currentPage;
  };
  const clearSessionStorageKeys = () => {
    sessionStorage.clear();
  };
  const removeItemFromSession = (sessionStorageKey: SessionStorageKeys) => {
    sessionStorage.removeItem(sessionStorageKey);
  };
  return {
    setHomePageScrollPosition,
    getHomePageScrollPosition,
    setHomeWindowScrollPosition,
    getHomeWindowScrollPosition,
    setCurrentPage,
    clearSessionStorageKeys,
    removeItemFromSession,
    getPreviousPageOnInit,
    getRecordsPerPage,
    getCurrentPageFromSession,
  };
};

export default uiScrollHelper;
