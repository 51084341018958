import React from "react";
import { useEffect } from "react";
import styles from "../dashboard/MainContent.module.css";
import { TextField, Grid, MenuItem, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useApi from "../../useApi";
import { CompanyProfile } from "../../models/StockProfile";
import { debounce } from "lodash";
import useState from "react-usestateref";
import { useNavigate } from "react-router-dom";
import appRouteHelper from "../utils/app-route-helper";
import BuyCoffeeLogo from "../../images/bmc-logo.png";
// import BuyMeACoffeeWidget from "../common/buymecoffeewidget"

function SearchStockList(props: any) {
  const navigate = useNavigate();
  const { getDetailRoute } = appRouteHelper();
  const { postRequest } = useApi();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchList, setSearchList] = useState<CompanyProfile[]>([]);

  useEffect(() => {
    const debouncedHandleApiCall = debounce(invokePostApiCall, 300); // 300 milliseconds debounce time
    if (searchQuery !== "") {
      debouncedHandleApiCall(
        `/api/stockProfiles/search?query=${searchQuery}`,
        (data: CompanyProfile[]) => {
          setSearchList(data);
        }
      );
    } else {
      setSearchList([]);
    }
    return () => {
      debouncedHandleApiCall.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  const handleFundClick = () => {
    window.open("https://buymeacoffee.com/TheStockbruhGuy", "_blank");
  };
  const invokePostApiCall = async (
    path: string,
    callback: (data: CompanyProfile[]) => void
  ) => {
    try {
      const response = await postRequest(path, {});
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const searchClick = (symbol: string) => {
    setSearchQuery("");
    setSearchList([]);
    if (symbol && props?.isStockSearch) {
      navigate(getDetailRoute(symbol), { replace: true });
    } else {
      props?.onSelectedItemChange(symbol);
    }
  };

  return (
    <>
      <Grid item md={11} className={styles.searchBar}>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <TextField
              variant="outlined"
              placeholder={props?.placeholder}
              className={styles.searchInput}
              value={searchQuery}
              onChange={onSearchChange}
              InputProps={{
                startAdornment: (
                  <SearchIcon color="action" className={styles.searchIcon} />
                ),
              }}
            />
          </div>
          {props?.isStockSearch && (
            <div className="flex-shrink-0 ms-2">
              <Tooltip title="Buy Me a coffee" arrow>
                <img
                  src={BuyCoffeeLogo}
                  alt="Buy Coffee"
                  className="cursor-pointer"
                  onClick={handleFundClick}
                  width={20}
                  height={20}
                />
              </Tooltip>
              {/* <BuyMeACoffeeWidget /> */}
            </div>
          )}
        </div>

        {/* show searchList for user to select from */}
        {Boolean(searchList?.length) && searchQuery && (
          <div className={styles.searchList}>
            {searchList.map((stock) => (
              <MenuItem
                key={stock.Symbol}
                onClick={() => searchClick(stock.Symbol)}
              >
                {stock.companyName} - {stock.Symbol}
              </MenuItem>
            ))}
          </div>
        )}
      </Grid>
    </>
  );
}

export default SearchStockList;
