import {
  createTheme,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import styles from "./stockbruhDrawer.module.css";
import uiScrollHelper from "../utils/ui-scroll-helper";
import SearchStockList from "./searchStockList";
import MainContent from "../dashboard/MainContent";
import { useEffect } from "react";
import { UserProfile } from "../../models/StockProfile";
import useApi from "../../useApi";
import { useClerk } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import Spinner from "../loader/spinner";
import CoffeeIcon from "../../images/icon-coffee-cup.svg";
// import chatBot from "../../images/chat-bot-ai.svg";

import { ApplicationRoutes } from "../../constants/application-routes";
import React from "react";
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  minWidth: 0,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function StockBruhDrawer() {
  const theme = useTheme();
  //eslint-disable-next-line
  const [open, setOpen] = useState(true);
  const { sendRequest } = useApi();
  const { signOut } = useClerk();
  const navigate = useNavigate();
  const { clearSessionStorageKeys } = uiScrollHelper();
  const defaultUserProfile: UserProfile = {
    id: "",
    name: "",
    email: "",
    phone: "",
    surname: "",
    imageUrl: "",
    username: "",
    verification: { email: "", phone: "" },
    twofactorenabled: "",
  };
  const urlLocation = window.location.pathname;
  const [userProfile, setUserProfile] = useState(defaultUserProfile);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (menuItem: string) => {
    navigate(menuItem);
  };

  const isSelectedMenu = (menuItem: string) => {
    return urlLocation.indexOf(menuItem) > -1;
  };

  useEffect(() => {
    toggleLeftNav();
    window.addEventListener("resize", () => {
      toggleLeftNav();
    });
    const handleApiCall = async <T,>(
      path: string,
      callback: (data: T) => void
    ) => {
      try {
        //eslint-disable-next-line
        const response = await sendRequest(path);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        callback(data);
      } catch (error) {}
    };
    handleApiCall<UserProfile>("/api/userProfile", (data) =>
      setUserProfile(data)
    );
    //eslint-disable-next-line
  }, []);
  //eslint-disable-next-line
  const toggleLeftNav = () => {
    if (window.innerWidth < 960) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  const handleFundClick = () => {
    window.open("https://buymeacoffee.com/TheStockbruhGuy");
  };
  const fontTheme = createTheme({
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Inter';
          }
        `,
      },
    },
  });
  //eslint-disable-next-line
  return (
    <ThemeProvider theme={fontTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          style={{ backgroundColor: "white" }}
        >
          <Toolbar>
            <IconButton
              style={{ color: "rgba(0, 0, 0, 0.54)" }}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <SearchStockList
              isStockSearch={true}
              placeholder={"Search for Stocks , ETFs and Funds here"}
            />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <List>
            <img
              src="/images/stockbruh_logo.png"
              alt="Company Logo"
              style={{
                height: "3.2rem",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginBottom: "0.5rem",
                objectFit: "contain",
              }}
            />
            <Avatar
              alt="User Image"
              src={userProfile.imageUrl}
              style={{ left: "35%", width: "4rem", height: "4rem" }}
            />
            <div
              style={{
                color: "#0b1215",
                textAlign: "center",
                fontSize: "1.2rem",
                fontWeight: "200",
                margin: "0.3rem 0",
              }}
            >
              {userProfile.name}
            </div>
            <div
              style={{
                textAlign: "center",
                color: "#0d6efd",
                fontSize: "0.9rem",
              }}
            >
              <a
                href={`https://${process.env.REACT_APP_CLERK_ACCOUNTS_HOST}/user?redirect_url=https://${process.env.REACT_APP_CLERK_ACCOUNTS_REDIRECT_HOST}/home`}
              >
                {" "}
                Edit Profile
              </a>
            </div>
          </List>
          <Divider />
          <ListItem
            key="Home"
            disablePadding
            onClick={() => handleMenuClick(ApplicationRoutes.Home)}
            className={isSelectedMenu("home") ? styles.selectedMenuItem : ""}
          >
            <ListItemButton>
              <HomeIcon style={{ color: "#333" }} />
              <ListItemText primary="Home" style={{ marginLeft: "0.4rem" }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Favorites"
            disablePadding
            onClick={() => handleMenuClick(ApplicationRoutes.Favorites)}
            className={
              isSelectedMenu("favorites") ? styles.selectedMenuItem : ""
            }
          >
            <ListItemButton>
              <FavoriteIcon style={{ color: "#333" }} />
              <ListItemText
                primary="Favorites"
                style={{ marginLeft: "0.4rem" }}
              />
            </ListItemButton>
          </ListItem>
          {/* AI */}
          {/* <ListItem
            key="AI"
            disablePadding
            onClick={() => handleMenuClick(ApplicationRoutes.AIHub)}
            className={
              isSelectedMenu("aihub") ? styles.selectedMenuItem : ""
            }
          >
            <ListItemButton>
              <img src={chatBot} alt="chatBot" width={24} height={24} />
              <ListItemText
                primary="AI Hub(Beta)"
                style={{ marginLeft: "0.4rem" }}
              />
            </ListItemButton>
          </ListItem> */}
          <ListItem onClick={handleFundClick} disablePadding>
            <ListItemButton>
              <img src={CoffeeIcon} alt="CoffeeIcon" width={30} height={20} />
              <ListItemText primary="Support Us" />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Logout"
            disablePadding
            onClick={() =>
              signOut(() => {
                clearSessionStorageKeys();
                navigate("/");
              })
            }
            className={isSelectedMenu("Logout") ? styles.selectedMenuItem : ""}
          >
            <ListItemButton>
              <ExitToAppIcon style={{ color: "#333" }} />
              <ListItemText primary="Logout" style={{ marginLeft: "0.4rem" }} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </Drawer>
        <Main open={open}>
          <Spinner />
          <MainContent></MainContent>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
