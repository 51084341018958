import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useApi from "../../useApi";
import moment from "moment";
import { Button, ButtonGroup, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SbLineChart from "../common/charts/sbLineChart";
import chartHelpers from "../utils/chart-helpers";
import { HistoricalApiResponse } from "./model/historical-price-response.model";
import { Box } from "@mui/material";
import { a11yProps, CustomTabPanel } from "../common/tabs/SbTabs";
import SearchStockList from "../common/searchStockList";

const StockHistoryChart = (props: any) => {
  const { postRequest } = useApi();
  let defaultChartData: any[] = [];
  // eslint-disable-next-line
  const [fourtyYChartData, setFourtyYChartData] = useState(defaultChartData); // eslint-disable-next-line
  const [oneDayChartData, setOneDayChartData] = useState(defaultChartData);
  // eslint-disable-next-line
  const [isChartDataLoaded, setIsChartDataLoaded] = useState(false);
  const [isPriceChartByPercentage, setIsPriceChartByPercentage] =
    useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [value, setValue] = useState(1);
  const [chartData, setChartData] = useState<any>([]);
  const [changePercentKey, setChangePercentKey] =
    useState<string>("changePercent5D");
  const [chartType, setChartType] = useState<string>("5D");
  const [compareSymbol, setCompareSymbol] = useState<string>("");
  const [compareChartHistoricalData, setCompareChartHistoricalData] =
    useState<any>([]);
  const dateFormat = "YYYY-MM-DD";
  let currentDate = new Date();
  const {
    historicalChartOptions,
    getHistoricalDataChart,
    getOneDayHistoricalDataChart,
    getParsedHistoricalData,
  } = chartHelpers();
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileView(window.innerWidth < 960);
    });
    initHistoryByYear(40);
    getOneDayHistoricalData();
    // eslint-disable-next-line
  }, []);
  const handlePostApiCall = async <T,>(
    path: string,
    postBody: any,
    callback: (data: T) => void
  ) => {
    try {
      const response = await postRequest(path, postBody);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      callback(data);
    } catch (error) {}
  };
  const getOneDayHistoricalData = () => {
    const postData = {
      symbol: props?.symbol,
      fromDate: "",
      toDate: "",
    };
    handlePostApiCall<any>(
      "/api/stockProfileDetails/one-day-historical",
      postData,
      (data) => {
        setOneDayChartData(data);
      }
    );
  };
  const loadChartByTab = (
    newValue: number,
    isPercentageView: boolean,
    foutyYearHistorical: any,
    tabChange?: boolean
  ) => {
    let changePercentKey = "";
    let chartType = "";
    switch (newValue) {
      case 1:
        changePercentKey = "changePercent5D";
        chartType = "5D";
        break;
      case 2:
        changePercentKey = "changePercent1M";
        chartType = "1M";
        break;
      case 3:
        changePercentKey = "changePercent6M";
        chartType = "6M";
        break;
      case 4:
        changePercentKey = "changePercent1Y";
        chartType = "1Y";
        break;
      case 5:
        changePercentKey = "changePercent5Y";
        chartType = "5Y";
        break;
      case 6:
        changePercentKey = "changePercent10Y";
        chartType = "10Y";
        break;
      case 7:
        changePercentKey = "changePercent20Y";
        chartType = "20Y";
        break;
      case 8:
        changePercentKey = "changePercent40Y";
        chartType = "40Y";
        break;
    }
    setChangePercentKey(changePercentKey);
    setChartType(chartType);
    setValue(newValue);
    const chartSeriesData = getHistoricalDataChart(
      props?.symbol,
      foutyYearHistorical,
      chartType,
      changePercentKey,
      isPercentageView
    );
    if (
      tabChange &&
      isPercentageView &&
      compareChartHistoricalData?.length > 0
    ) {
      const newCompareSeries = getCompareChartSeriesStateonTabChange(
        compareSymbol,
        chartType,
        changePercentKey
      );
      if (newCompareSeries) {
        updateCompareChartSeries(chartSeriesData, newCompareSeries);
      }
    } else {
      setChartData(chartSeriesData);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    loadChartByTab(newValue, isPriceChartByPercentage, fourtyYChartData, true);
  };
  const onPriceViewClick = () => {
    setIsPriceChartByPercentage(false);
    setCompareChartHistoricalData([]);
    setCompareSymbol("");
    loadChartByTab(value, false, fourtyYChartData);
    props?.chartTypeChange(false);
  };
  const onPercentageViewClick = () => {
    setIsPriceChartByPercentage(true);
    loadChartByTab(value, true, fourtyYChartData);
    props?.chartTypeChange(true);
  };
  const initHistoryByYear = (yearFrequency: number) => {
    const fromDate = moment(
      new Date().setFullYear(currentDate.getFullYear() - yearFrequency)
    ).format(dateFormat);
    const formattedToDate = moment(currentDate).format(dateFormat);
    const postData = {
      symbol: props?.symbol,
      fromDate: fromDate,
      toDate: formattedToDate,
    };
    handlePostApiCall<HistoricalApiResponse>(
      "/api/stockProfileDetails/historical",
      postData,
      (data) => {
        setFourtyYChartData(data?.historical);
        setIsChartDataLoaded(true);
        setTimeout(() => {
          loadChartByTab(1, false, data?.historical);
        }, 50);
      }
    );
  };
  const compareChartBySymbol = (symbol: string) => {
    setCompareSymbol(symbol);
    const fromDate = moment(
      new Date().setFullYear(currentDate.getFullYear() - 40)
    ).format(dateFormat);
    const formattedToDate = moment(currentDate).format(dateFormat);
    const postData = {
      symbol: symbol,
      fromDate: fromDate,
      toDate: formattedToDate,
    };
    handlePostApiCall<HistoricalApiResponse>(
      "/api/stockProfileDetails/historical",
      postData,
      (data) => {
        setCompareChartHistoricalData(data?.historical);
        const newCompareSeries = getParsedHistoricalData(
          symbol,
          data?.historical,
          chartType,
          changePercentKey
        );
        updateCompareChartSeries(chartData, newCompareSeries);
      }
    );
  };
  const getCompareChartSeriesStateonTabChange = (
    symbol: string,
    chartType: string,
    chartSeriesKey: string
  ) => {
    const compareStockSeriesResult = getParsedHistoricalData(
      symbol,
      compareChartHistoricalData,
      chartType,
      chartSeriesKey
    );
    return compareStockSeriesResult;
  };
  const updateCompareChartSeries = (
    mainChartData: any,
    newCompareSeries: any
  ) => {
    const labels =
      mainChartData?.labels?.length > newCompareSeries?.labels?.length
        ? mainChartData?.labels
        : newCompareSeries?.labels;
    setChartData((prev: any) => ({
      ...prev,
      datasets: [mainChartData?.datasets[0], newCompareSeries?.chartData],
      labels: labels,
    }));
  };
  const onSelectedItemChange = (symbol: string) => {
    compareChartBySymbol(symbol);
  };

  function onToolTipChange(data: any) {
    props.onToolTipChange(data);
  }
  return isChartDataLoaded ? (
    <Grid container>
      <Grid item xs={isMobileView ? 12 : 9}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="1D" {...a11yProps(0)} />
          <Tab label="5D" {...a11yProps(1)} />
          <Tab label="1M" {...a11yProps(2)} />
          <Tab label="6M" {...a11yProps(3)} />
          <Tab label="1Y" {...a11yProps(4)} />
          <Tab label="5Y" {...a11yProps(5)} />
          <Tab label="10Y" {...a11yProps(6)} />
          <Tab label="20Y" {...a11yProps(7)} />
          <Tab label="40Y" {...a11yProps(8)} />
        </Tabs>
      </Grid>
      <Grid item xs={isMobileView ? 12 : 3}>
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="Basic button group"
          className={isMobileView ? "chart-mobile-layout" : ""}
        >
          <Button
            variant={isPriceChartByPercentage ? "outlined" : "contained"}
            className="no-txt-transform"
            onClick={onPriceViewClick}
          >
            Price View
          </Button>
          <Button
            variant={isPriceChartByPercentage ? "contained" : "outlined"}
            className="no-txt-transform"
            onClick={onPercentageViewClick}
          >
            Percentage View
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <CustomTabPanel value={value} index={0}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 0 && oneDayChartData?.length > 0 && (
                <SbLineChart
                  data={getOneDayHistoricalDataChart(
                    props?.symbol,
                    oneDayChartData
                  )}
                  options={historicalChartOptions(
                    props?.currency,
                    undefined,
                    false
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={false}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 1 && fourtyYChartData.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 2 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={3}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 3 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={4}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 4 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={5}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 5 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={6}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 6 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>{" "}
        <CustomTabPanel value={value} index={7}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 7 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          {
            <Box sx={{ width: "100%", height: "30vh" }}>
              {value === 8 && fourtyYChartData?.length > 0 && (
                <SbLineChart
                  data={chartData}
                  options={historicalChartOptions(
                    props?.currency,
                    onToolTipChange,
                    isPriceChartByPercentage
                  )}
                  width="100%"
                  height="50vh"
                  displayTimeMachineIcon={true}
                />
              )}
            </Box>
          }
        </CustomTabPanel>
      </Grid>
      {isPriceChartByPercentage && (
        <Grid item xs={12} style={{ marginTop: "8rem" }}>
          Compare
          <SearchStockList
            isStockSearch={false}
            onSelectedItemChange={onSelectedItemChange}
            placeholder={"Compare Custom Stocks or Index"}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <div></div>
  );
};

export default StockHistoryChart;
